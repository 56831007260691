import {subcatRedesign} from '../../utils/constants/signalmanConstants';

class SubcatRedesignTest {
  constructor() {
    this.flag = subcatRedesign;
    this.initEventListeners = this.initEventListeners.bind(this);
    this.trackInteractions = this.trackInteractions.bind(this);
    this.fireMetrics();
    this.dataLayerPush();
    this.initEventListeners();
    this.trackDoubleHeader();
  }

  fireMetrics() {
    return CustomInk.Metrics.fireEvent(
      'testing',
      '2024 07 22 catalog page redesign',
      `2024 07 22 catalog page redesign ${this.flag}`
    );
  }

  dataLayerPush() {
    return window.dataLayer.push({
      event: 'ab_test_group',
      ab_test_name: '2024 07 22 catalog page redesign',
      ab_test_location: 'catalog',
      ab_test_group: this.flag
    });
  }

  trackDoubleHeader() {
    if (document.querySelectorAll('.aa-Autocomplete').length > 1) {
      CustomInk.Metrics.fireEvent('catalog', 'double searchbar', `catalog page redesign ${this.flag}`);
      window.dataLayer.push({
        event: 'ab_test_alert',
        alert_name: 'double searchbar',
        alert_location: 'catalog',
        alert_group: `catalog page redesign ${this.flag}`
      });
    }
  }

  trackInteractions(action, label, value) {
    CustomInk.Metrics.fireEvent('catalog subcategory', label, value);

    return window.dataLayer.push({
      event: 'interaction',
      interaction_category: 'catalog subcategory',
      interaction_action: action, 
      interaction_label: label, 
      interaction_value: value 
    });
  }

  initEventListeners() {
    this.trackSidebarClicks();
    this.trackCategoryCardClicks();
    this.trackCircleTileClicks();
    this.trackSquareTileClicks();
    this.trackRectangleTileClicks();
    this.trackPopularPicksClicks();

    document.addEventListener('showtooltip', (event) => this.toolTipHandler(event));
  }

  toolTipHandler(event) {
    const styleId = event.detail.styleId;
    const sourceEvent = event.detail.type;

    this.trackInteractions(`pricing details ${sourceEvent}`, 'popular picks', styleId);
  }

  trackSidebarClicks() {
    const topSidebarItems = document.querySelectorAll('.pc-Subcategories-sidebar > ul > li');

    topSidebarItems.forEach(item => {
      const heading = item.querySelector('h4');
      const headerContent = heading ? heading.textContent : '';
      const links = item.querySelectorAll('ul a');

      links.forEach(link => {
        link.addEventListener('click', (event) => {
          this.trackInteractions('sidebar click', headerContent, link.textContent);
        });
      });
    });
  }

  trackCategoryCardClicks() {
    const categoryCards = document.querySelectorAll('.pc-Subcategories-section-category-card');

    categoryCards.forEach(card => {
      const cardTitle = card.querySelector('h3');
      const cardLink = card.querySelector('a');
      const cardTitleContent = cardTitle ? cardTitle.textContent : link.textContent || '';

      cardLink.addEventListener('click', (event) => {
        this.trackInteractions('card click', 'styles', cardTitleContent);
      });
    });
  }

  trackCircleTileClicks() {
    const circleTiles = document.querySelectorAll('.pc-Subcategories-section-tile.circle');

    circleTiles.forEach(tile => {
      const tileLabel = tile.querySelector('.pc-Subcategories-section-tile-label'); 
      const tileLink = tile.querySelector('a');
      const tileLabelText = tileLabel ? tileLabel.textContent : '';

      tileLink.addEventListener('click', (event) => {
        this.trackInteractions('card click', 'shop by type', tileLabelText);
      });
    });
  }

  trackSquareTileClicks() {
    const squareTiles = document.querySelectorAll('.pc-Subcategories-section-tile.square');

    squareTiles.forEach(tile => {
      const tileLabel = tile.querySelector('.pc-Subcategories-section-tile-label'); 
      const tileLink = tile.querySelector('a');
      const tileLabelText = tileLabel ? tileLabel.textContent : '';

      tileLink.addEventListener('click', (event) => {
        this.trackInteractions('card click', 'featured brands', tileLabelText);
      });
    });
  }

  trackRectangleTileClicks() {
    const rectangleTiles = document.querySelectorAll('.pc-Subcategories-section-tile.rectangle');

    rectangleTiles.forEach(tile => {
      const tileLabel = tile.querySelector('.pc-Subcategories-section-tile-label'); 
      const tileLink = tile.querySelector('a');
      const tileLabelText = tileLabel ? tileLabel.textContent : '';

      tileLink.addEventListener('click', (event) => {
        this.trackInteractions('card click', 'curated collections', tileLabelText);
      });
    });
  }

  trackPopularPicksClicks() {
    const pills = document.querySelectorAll('.pc-Subcategories-section-nav-item.products');
    const cards = document.querySelectorAll('.pc-Subcategories-section-product-card');

    pills.forEach(pill => {
      const pillText = pill.textContent;
      pill.addEventListener('click', (event) => {
        this.trackInteractions('tab click', 'popular picks', pillText);
      });
    });

    cards.forEach(card => {
      const cardLink = card.querySelector('a');
      const isCatCard = card.classList.contains('product-find');
      const cardContent = isCatCard ? cardLink.textContent : '';
      const action = isCatCard ? 'button click' : 'product click';
      const styleId = card.dataset.styleId;

      card.addEventListener('click', (event) => {
        this.trackInteractions(action, 'popular picks', (isCatCard ? cardContent : styleId));
      });
    });
  }
}

ProductCatalog.SubcatRedesignTest = new SubcatRedesignTest();
